.help-wrapper {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    position: relative;
    align-content: center;
  }
  
  .help-icon {
    color: #666;
    transition: color 0.2s ease;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  
  .help-icon:hover {
    color: #333;
  }